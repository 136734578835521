export const shippingDataModule = {
  state: () => ({
    shippingData: [],
  }),
  mutations: {
    ADD_INITIAL_DATA(state, data) {
      state.shippingData = data;
    },
  },
  actions: {
    setShippingData(context, payload) {
      context.commit("ADD_INITIAL_DATA", payload);
    },
  },
  getters: {
    shippingData: (state) => {
      return state.shippingData;
    },
  },
};
