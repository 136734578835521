<template>
  <v-footer app color="white" class="footerpart" inset>
    <v-col cols="10">
      <span>© {{ new Date().getFullYear() }} — Developed by Premdas VM</span>
    </v-col>
    <v-col cols="2">
      <span>{{ Intl.DateTimeFormat().resolvedOptions().timeZone }}</span>
    </v-col>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>
<style lang="scss">
.v-application .footerpart {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
</style>
