<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
    <router-view />
    <notifications position="bottom left" />
    <OverlayLoading />
  </v-app>
</template>

<script>
import { getAllCategories, getAllBranches, getAllColors } from "./common/general-api";
import OverlayLoading from "./components/OverlayLoading.vue";

export default {
  name: "App",

  components: { OverlayLoading },

  async created() {
    await getAllCategories();
    await getAllBranches();
    await getAllColors();
  },
};
</script>

<style lang="css">
.el-icon-arrow-down {
  margin-left: 5px;
  color: black;
  font-weight: bolder;
}
.el-row {
  margin-bottom: 20px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
