import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";

import "viewerjs/dist/viewer.css";
import "vue-good-table/dist/vue-good-table.css";

// Custom Package Registrations
import VueImageChooser from "vue-image-chooser";
Vue.use(VueImageChooser);
import Notifications from "vue-notification";
Vue.use(Notifications);
import VueViewer from "v-viewer";
Vue.use(VueViewer);
import VueGoodTablePlugin from "vue-good-table";
Vue.use(VueGoodTablePlugin);
// End
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/reset.css";
import locale from "element-ui/lib/locale/lang/en";
Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("accessToken")}`;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
