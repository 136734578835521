import Vue from "vue";
import VueRouter from "vue-router";

import MainLayout from "@/layouts/MainLayout.vue";

Vue.use(VueRouter);

const routes = new VueRouter({
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      // meta: { requireAuth: false },
    },
    {
      path: "",
      component: MainLayout,
      meta: { requireAuth: true },
      children: [
        {
          path: "/",
          name: "home",
          component: () => import("@/views/Home/Home.vue"),
          meta: { requireAuth: true },
        },
        // Products
        {
          path: "products",
          name: "Products",
          component: () => import("@/views/Products/Products.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "newProduct",
          name: "NewProduct",
          component: () => import("@/views/Products/NewProduct.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "users",
          name: "Users",
          component: () => import("@/views/Users/Users.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "orders",
          name: "Orders",
          component: () => import("@/views/Orders/Orders.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "pendingOrders",
          name: "Orders",
          component: () => import("@/views/Orders/Pending/PendingOrders.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "confirmedOrders",
          name: "Orders",
          component: () => import("@/views/Orders/Confirmed/Confirmed.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "requests",
          name: "Requests",
          component: () => import("@/views/Requests/Requests.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "pendingShipments",
          name: "Pending Shipments",
          component: () => import("@/views/Shipping/Pending/Pending.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "shipmentHistory",
          name: "Shipment History",
          component: () => import("@/views/Shipping/History/History.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "shipmentHistoryDetails",
          name: "shipmentHistoryDetails",
          component: () => import("@/views/Shipping/History/HistoryDetails.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "priceConversion",
          name: "Price Conversion",
          component: () => import("@/views/PriceConversion/Index.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "dailyOpeningStock",
          name: "Daily Opening Stock",
          component: () => import("@/views/DailyOpeningStock/DailyOpeningStock.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "branches",
          name: "Branches",
          component: () => import("@/views/General/Branches/Branches.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "Subcategories",
          name: "Subcategories",
          component: () => import("@/views/General/Subcategories/Subcategories.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "colors",
          name: "Product Colors",
          component: () => import("@/views/General/ProductColors/Colors.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "trackProduct",
          name: "Track product",
          component: () => import("@/views/Reports/TrackProduct/index.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "pendingShippingDetails",
          name: "Pending Shipping Details",
          component: () => import("@/views/Shipping/PendingDetails/index.vue"),
          meta: { requireAuth: true },
        },

        // IGoal Stock
        {
          path: "igoalStock",
          name: "IGoal Stock",
          component: () => import("@/views/IGoalStock/Stock.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "igoalStockRequests",
          name: "IGoal Stock Requests",
          component: () => import("@/views/IGoalStock/Requests.vue"),
          meta: { requireAuth: true },
        },
        {
          path: "igoalStockRequestsHistory",
          name: "IGoal Stock Requests History",
          component: () => import("@/views/IGoalStock/History.vue"),
          meta: { requireAuth: true },
        },
      ],
    },
  ],
  mode: "hash",
});

routes.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (!localStorage.getItem("accessToken")) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default routes;
