import axios from "axios";
import store from "../store";

export async function getAllCategories() {
  axios
    .get("category")
    .then((res) => {
      store.dispatch("addToCategories", { data: res.data });
    })
    .catch((err) => console.log(err));
}

export async function getAllBranches() {
  axios
    .get("branch")
    .then((res) => {
      store.dispatch("addToBranches", { data: res.data });
    })
    .catch((err) => console.log(err));
}

export async function getAllColors() {
  axios
    .get("colors")
    .then((res) => {
      store.dispatch("addToColors", { data: res.data });
    })
    .catch((err) => console.log(err));
}

export async function uploadFile(data, path) {
  let formData = new FormData();

  formData.append("file", data, data.name);
  formData.append("path", path);

  const res = await axios.post(`storage/uploadFile`, formData);

  return res.data;
}

export async function uploadImage(data, path) {
  let formData = new FormData();

  formData.append("image", data, data.name);
  formData.append("path", path);

  const res = await axios.post(`storage/uploadImage`, formData);

  return res.data;
}
